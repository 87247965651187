
  import Vue from "vue";
  import {MODAL_IDS, MODALS_LOADED} from "@/utils/modal";
  import {EVENTS} from "@/utils/events";

  const GLOBAL_BLOCK_GAME_ID = 0;

  export default Vue.extend({
    name: "pwa-user-block-management-modal",
    data() {
      return {
        loadContent: false,
        games: [],
        gamesBlocked: {} as Record<string|number, boolean>,
        globalBlockGameId: GLOBAL_BLOCK_GAME_ID,
      };
    },
    mounted(): void {
      MODALS_LOADED[this.modalId] = true;
    },
    computed: {
      modalId(): string {
        return MODAL_IDS.USER_BLOCK_MANAGEMENT;
      },
      username(): string {
        return this.$store.getters.modalProps.username || '';
      },
      generalBlock(): boolean {
        return Boolean(this.gamesBlocked[GLOBAL_BLOCK_GAME_ID]);
      },
    },
    methods: {
      onShow() {
        this.getUserBlockManagementPage();
        this.loadContent = true;
      },
      onHidden() {
        this.loadContent = false;
      },
      async getUserBlockManagementPage() {
        this.showLoader(true);
        try {
          const { data } = await this.$http.page.getUserBlockManagementPage(this.username);
          const gamesIdsBlockedList = data.data.games_ids_blocked || [];
          const gamesBlocked: Record<string|number, boolean> = {};
          gamesIdsBlockedList.forEach((gameBlockedId: number|string) => {
            gamesBlocked[gameBlockedId] = true;
          });
          this.gamesBlocked = gamesBlocked;
          this.games = data.data.games || [];
        } catch (e) {
          this.showToastError(this.$t('139'), this.$t('140'));
        } finally {
          this.showLoader(false);
        }
      },
      onCancelClick() {
        this.closeModal();
      },
      closeModal() {
        this.hideModalById(this.modalId);
      },
      async blockUser() {
        try {
          this.showLoader(true);
          const gamesBlocked: number[] = [];
          if (this.generalBlock) {
            gamesBlocked.push(GLOBAL_BLOCK_GAME_ID);
          } else {
            Object.keys(this.gamesBlocked).forEach((gameId: string) => {
              if (this.gamesBlocked[gameId]) {
                gamesBlocked.push(parseInt(gameId));
              }
            });
          }

          await this.$http.user.postBlockUser(this.username, gamesBlocked);
          this.showLoader(false);
          this.$root.$emit(EVENTS.USER_BLOCK_MANAGEMENT_UPDATED);
          this.closeModal();
        } catch (e) {
          this.showLoader(false);
          this.showToastError(this.$t('139'), this.$t('140'));
        }
      },
    }
  });
